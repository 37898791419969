/**
 * French locale
 */
import saMessages from '../locales/fr_FR';

const saLang = {
    messages: {
        ...saMessages
    },
    locale: 'fr-FR'
};
export default saLang;
