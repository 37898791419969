/**
 * English locale
 */
import enMessages from '../locales/en_US';

const EnLang = {
    messages: {
        ...enMessages
    },
    locale: 'en-US'
};
export default EnLang;